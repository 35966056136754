import { useContext } from 'react';
import { TroubleShootingHandler } from '../../../../Pages/MainComponents/TroubleShooting/TroubleShooting';
import AudioService from '../../../../Services/AudioService';
import { CustomerServiceContactService } from '../../../../Services/Configuration/CustomerServiceContactService';
import { goToUrl } from '../../../../Utils/NavigationUtils';
import { RedoHLAAContext, RedoHLAAState } from '../RedoHLAAContext';

function useRedoHLAATroubleShootingHandler(): TroubleShootingHandler {
    const { setRedoHLAAState, info } = useContext(RedoHLAAContext);

    const onStartAudioContext = async () => {
        await AudioService.serviceInstance.createNewAudioContext();
    };

    const retryBeepConfirmationThresholdReached = () =>
        info.current.retryBeepConfirmationCount >=
        info.current.retryBeepConfirmationCountThreshold;

    const onRetry = async () => {
        await onStartAudioContext();
        setRedoHLAAState(RedoHLAAState.OneSideSetUp);
    };

    const openConnectionSupportLink = () => {
        goToUrl(
            CustomerServiceContactService.getCountrySpecificOrionConnectionCheckAddress(
                false
            ),
            true
        );
    };

    const linkCallBacks = {
        connectionCheckSupport: openConnectionSupportLink,
    };

    return {
        onRetry,
        linkCallBacks,
        retryBeepConfirmationThresholdReached,
    };
}

export default useRedoHLAATroubleShootingHandler;
