import { useContext } from 'react';
import { VoidReturn } from '../../../../Utils/PageUtils';
import { MobileDeviceUnmutedCheckContext } from '../MobileDeviceUnmutedCheckContext';
import { MobileDeviceUnmutedCheckState } from '../MobileDeviceUnmutedCheckModel';
import AudioService from '../../../../Services/AudioService';

export type MobileDeviceUnmutedCheckPlayPageHandler = {
    onPlayMedia: () => VoidReturn;
};

export default function useMobileDeviceUnmutedCheckPlayPageHandler(): MobileDeviceUnmutedCheckPlayPageHandler {
    const { setCurrentMobileDeviceUnmutedCheckState } = useContext(
        MobileDeviceUnmutedCheckContext
    );

    const onPlayMedia = async () => {
        await AudioService.serviceInstance.prepAudio(
            '/resources/audio/unmuted-check-jingle.mp3',
            'UnmutedCheckJingle'
        );
        setCurrentMobileDeviceUnmutedCheckState(
            MobileDeviceUnmutedCheckState.Confirmation
        );
    };

    return {
        onPlayMedia,
    };
}
