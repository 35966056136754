import { useContext } from 'react';
import ArcToneService from '../../../../Services/ArcToneService';
import AudioService from '../../../../Services/AudioService';
import DeviceManagementService from '../../../../Services/DeviceManagementService';
import HLAAService from '../../../../Services/HLAAService';
import LocalStorageService from '../../../../Services/LocalStorageService';
import LoggingService from '../../../../Services/LoggingService';
import TelemetryService from '../../../../Services/Monitoring/TelemetryService';
import PatientManagementService from '../../../../Services/PatientManagementService';
import { getUserDeviceModel } from '../../../../Services/ResourceService';
import { SliderStateType } from '../../../../SharedComponents/Slider/CustomSlider';
import { Workflow } from '../../../../Utils/CompletedWorkflow';
import { VoidReturn } from '../../../../Utils/PageUtils';
import { RunningState, useRunning } from '../../../../Utils/UseRunning';
import { SpeechComfortTestContext } from '../SpeechComfortTestContext';

export type SpeechComfortTestDetailPageHandler = {
    goFinishAction: () => VoidReturn;
    onChangeLeftSlider: (value: number) => VoidReturn;
    onChangeRightSlider: (value: number) => VoidReturn;
    onChangeCommittedLeftSlider: (value: number) => VoidReturn;
    onChangeCommittedRightSlider: (value: number) => VoidReturn;
    getSliderStateTypeForSide: (side: string) => SliderStateType;
    handleSliderStart: (event: React.TouchEvent<HTMLElement>) => VoidReturn;
    loadingLeft: boolean;
    errorLeft: string;
    loadingRight: boolean;
    errorRight: string;
};

function useSpeechComfortTestDetailsPageHandler(): SpeechComfortTestDetailPageHandler {
    const { info, setSpeechComfortTestInfo, exitSpeechComfort } = useContext(
        SpeechComfortTestContext
    );

    const [loadingLeft, errorLeft, setRunningLeft] = useRunning();
    const [loadingRight, errorRight, setRunningRight] = useRunning();

    const leftMin = info.masterGain.display['left'].min;
    const rightMin = info.masterGain.display['right'].min;
    const leftMax = info.masterGain.display['left'].max;
    const rightMax = info.masterGain.display['right'].max;

    const leftCenterPosition = leftMax - Math.abs((leftMax - leftMin) / 2);
    const rightCenterPosition = rightMax - Math.abs((rightMax - rightMin) / 2);

    const getDeviceIdBySide = (side: string): string => {
        return LocalStorageService.serviceInstance.getDeviceId(side);
    };

    const getBrandIdBySide = (side: string): number => {
        return LocalStorageService.serviceInstance.getDeviceBrandId(side);
    };

    const getPairingAddressBySide = (side: string): number => {
        return LocalStorageService.serviceInstance.getPairingAddress(side);
    };

    const getMasterGainIdFromObject = (side: string, key: number) => {
        let masterGainIdForSide = 0;

        if (info.masterGain.uiToValueMap.get(side) !== undefined) {
            const masterGainUiLeftObject =
                info.masterGain.uiToValueMap.get(side);

            if (masterGainUiLeftObject !== undefined) {
                const masterGainUiLeftObjectValue =
                    masterGainUiLeftObject.get(key);

                if (masterGainUiLeftObjectValue !== undefined) {
                    masterGainIdForSide = masterGainUiLeftObjectValue;
                }
            }
        }

        return masterGainIdForSide;
    };

    const goFinishAction = async () => {
        try {
            await PatientManagementService.endWorkflow(
                Workflow.SpeechComfortTest
            );
            setSpeechComfortTestInfo({
                ...info,
                loading: true,
            });
            exitSpeechComfort(info);
        } catch (error) {
            setSpeechComfortTestInfo({
                ...info,
                loading: false,
            });
            throw error;
        }
    };

    const changeLoadingLeftRightAction = async (
        side: string,
        value: number | number[]
    ) => {
        try {
            side === 'left'
                ? setRunningLeft(RunningState.Running)
                : setRunningRight(RunningState.Running);
        } catch (error) {
            setRunningLeft(
                RunningState.Error,
                `Error when changing volume to ${value} on ${side} side.`
            );
            setRunningRight(
                RunningState.Error,
                `Error when changing volume to ${value} on ${side} side.`
            );
            throw error;
        }
    };

    const changeMasterGainValueAction = async (
        side: string,
        value: number | number[]
    ) => {
        try {
            setSpeechComfortTestInfo({
                ...info,
                loading: true,
            });
            const uiMasterGainValue = Number.parseInt(value.toString());
            const modelId = LocalStorageService.serviceInstance
                .getDeviceModelId(side)
                .toString();
            const couplingId =
                LocalStorageService.serviceInstance.getDeviceCouplingId(side);
            await AudioService.serviceInstance.initAudioContext();
            LoggingService.log({
                componentName: changeMasterGainValueAction.name,
                args: [
                    'changeMasterGainValue for ' +
                        side +
                        ' Value: ' +
                        info.masterGain.uiToValueMap
                            .get(side)
                            ?.get(uiMasterGainValue),
                ],
            });
            await AudioService.serviceInstance.prepAudio(
                ArcToneService.getMasterGain(
                    getBrandIdBySide(side),
                    getPairingAddressBySide(side),
                    getMasterGainIdFromObject(side, uiMasterGainValue),
                    modelId,
                    couplingId
                ),
                `${side} side set volume to ${info.masterGain.uiToValueMap
                    .get(side)
                    ?.get(uiMasterGainValue)}`
            );
            side === 'left'
                ? (info.latestLeftValue = uiMasterGainValue)
                : (info.latestRightValue = uiMasterGainValue);

            await DeviceManagementService.adjustMasterGain(
                LocalStorageService.serviceInstance.getPatientId(),
                getDeviceIdBySide(side),
                getMasterGainIdFromObject(side, uiMasterGainValue)
            );

            side === 'left'
                ? setRunningLeft(RunningState.Completed)
                : setRunningRight(RunningState.Completed);
            setSpeechComfortTestInfo({
                ...info,
                loading: false,
            });
        } catch (error) {
            setSpeechComfortTestInfo({
                ...info,
                loading: false,
            });
            throw error;
        }
    };

    const sendTrackingAudiogramEvent = (
        value: string,
        side: string,
        step: string
    ) => {
        TelemetryService.serviceInstance.TrackAnonymous(
            `Orion-SpeechComfort-${side}`,
            {
                Step: step,
                Model: getUserDeviceModel(),
                FreqAndDb: value,
            }
        );
    };

    const TrackLeftAdjustment = async (step: number) => {
        const hlaaTestId =
            LocalStorageService.serviceInstance.getLastCompletedHlaaTestId();
        const audiogram = await HLAAService.getResult(hlaaTestId);
        if (audiogram) {
            for (const value of Object.values(audiogram.leftAudiogramIndex)) {
                sendTrackingAudiogramEvent(value, 'Left', step.toString());
            }
        } else {
            LoggingService.error({
                componentName: useSpeechComfortTestDetailsPageHandler.name,
                args: ['Audiogram was not found'],
            });
        }
    };

    const TrackRightAdjustment = async (step: number) => {
        const hlaaTestId =
            LocalStorageService.serviceInstance.getLastCompletedHlaaTestId();
        const audiogram = await HLAAService.getResult(hlaaTestId);
        if (audiogram) {
            for (const value of Object.values(audiogram.rightAudiogramIndex)) {
                sendTrackingAudiogramEvent(value, 'Right', step.toString());
            }
        } else {
            LoggingService.error({
                componentName: useSpeechComfortTestDetailsPageHandler.name,
                args: ['Audiogram was not found'],
            });
        }
    };

    const onChangeCommittedLeftSlider = async (
        value: number
    ): Promise<void> => {
        await changeMasterGainValueAction('left', value);
        TrackLeftAdjustment(value - leftCenterPosition);
    };

    const onChangeCommittedRightSlider = async (
        value: number
    ): Promise<void> => {
        await changeMasterGainValueAction('right', value);
        TrackRightAdjustment(value - rightCenterPosition);
    };

    const onChangeLeftSlider = async (value: number): Promise<void> => {
        await changeLoadingLeftRightAction('left', value);
    };

    const onChangeRightSlider = async (value: number): Promise<void> => {
        await changeLoadingLeftRightAction('right', value);
    };

    const handleSliderStart = (event: React.TouchEvent<HTMLElement>): void => {
        event.preventDefault();
    };

    const getSliderStateTypeForSide = (side: string) => {
        const defaultSliderState: SliderStateType = {
            initialPosition: info.masterGain.display[side].startingPosition,
            posMin: info.masterGain.display[side].min,
            posMax: info.masterGain.display[side].max,
        };

        return defaultSliderState;
    };

    return {
        goFinishAction,
        onChangeLeftSlider,
        onChangeRightSlider,
        onChangeCommittedLeftSlider,
        onChangeCommittedRightSlider,
        getSliderStateTypeForSide,
        handleSliderStart,
        loadingLeft,
        errorLeft,
        loadingRight,
        errorRight,
    };
}

export default useSpeechComfortTestDetailsPageHandler;
