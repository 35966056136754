import React from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    List,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { RootState } from '../../../Redux/Reducers/RootReducer';
import INotificationState from '../../../Redux/Models/Notification/INotificationState';
import { updateNotification } from '../../../Redux/Reducers/NotificationSlice';
import CustomTypography, {
    TypographyType,
} from '../../Typography/CustomTypography';
import './AlertMessageStyle.scss';
import { NotificationCloseHandlerType } from '../../../Redux/Models/Notification/NotificationCloseHandlerType';
import ExitRouteService, {
    ExitStatus,
} from '../../../Services/ExitRouteService';

function AlertMessagePopUp(): JSX.Element {
    const notificationState = useSelector(
        (state: RootState) => state.notification
    ) as INotificationState;
    const { alertMessage } = notificationState;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const handleClose = async (index: number, reason = 'none') => {
        if (reason && reason === 'backdropClick') return;
        if (
            alertMessage &&
            alertMessage.userSelections &&
            alertMessage.userSelections[index] &&
            alertMessage.userSelections[index].action !==
                NotificationCloseHandlerType.Init
        ) {
            switch (alertMessage.userSelections[index].action) {
                case NotificationCloseHandlerType.Reload:
                    await window.location.reload();
                    break;
                case NotificationCloseHandlerType.UnauthenticatedExit:
                    ExitRouteService.exit(ExitStatus.InvalidTokenFail);
                    break;
                case NotificationCloseHandlerType.NetworkLossExit:
                    ExitRouteService.exit(ExitStatus.NetworkLossFail);
                    break;
                default:
                    break;
            }
        }
        dispatch(
            updateNotification({
                ...notificationState,
                alertMessage: {
                    title: alertMessage.title,
                    message: alertMessage.message,
                    detailMessage: alertMessage.detailMessage,
                    userSelections: alertMessage.userSelections,
                    isDisplayed: false,
                },
            })
        );
    };

    const populateMessages = (messages: string[]): JSX.Element[] => {
        const dialogContent = [];
        for (let index = 0; index < messages.length; index++) {
            dialogContent.push(
                <DialogContentText id={`alert-dialog-description${index + 1}`}>
                    {messages[index]}
                </DialogContentText>
            );
        }

        return dialogContent;
    };

    return (
        <Dialog
            open={alertMessage.isDisplayed}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {alertMessage.title}
            </DialogTitle>
            <DialogContent>
                {alertMessage.message && populateMessages(alertMessage.message)}
                {alertMessage.detailMessage ? (
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <CustomTypography
                                type={TypographyType.notoSansBody16px}
                                text={t(
                                    'common:Common_Feature:ErrorHandling:Text3'
                                )}
                                textAlign="left"
                            />
                        </AccordionSummary>
                        <AccordionDetails>
                            <CustomTypography
                                type={TypographyType.notoSansBody16px}
                                text={alertMessage.detailMessage}
                                textAlign="left"
                            />
                        </AccordionDetails>
                    </Accordion>
                ) : (
                    <></>
                )}
            </DialogContent>
            <DialogActions>
                <List>
                    {alertMessage?.userSelections?.map(
                        (x: { content: string }, index: number) => {
                            return (
                                <Button
                                    key={`selection-${x.content}`}
                                    id={`selection-${x.content}`}
                                    color="primary"
                                    onClick={() => {
                                        handleClose(index);
                                    }}
                                >
                                    {x.content}
                                </Button>
                            );
                        }
                    )}
                </List>
            </DialogActions>
        </Dialog>
    );
}

export default AlertMessagePopUp;
