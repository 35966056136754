import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import easyFitStore from './Redux/Store/EasyFitStore';
import { Provider } from 'react-redux';
import { t } from './Services/LocalizationService';
import { updateNotification } from './Redux/Reducers/NotificationSlice';
import { BrowserRouter } from 'react-router-dom';
import { StylesProvider } from '@material-ui/core/styles';
import { NotificationCloseHandlerType } from './Redux/Models/Notification/NotificationCloseHandlerType';
import { MainViewWorkflow } from './Pages/MainComponents/MainView/MainViewModel';
import LoggingService from './Services/LoggingService';

function appHeight() {
    console.log('Run App Height..');
    let vh = window.innerHeight / 100;
    document.querySelector(':root').style.setProperty('--vh', `${vh}px`);
}

window.addEventListener('resize', appHeight);
window.addEventListener('error', (event) => {
    displayUnhandledErrorMessage(event);
});

window.addEventListener('unhandledrejection', (event) => {
    displayUnhandledErrorMessage(event);
});

window.addEventListener('offline', () => {
    displayNetworkErrorMessage();
});
appHeight();

ReactDOM.render(
    <React.StrictMode>
        <Provider store={easyFitStore}>
            <BrowserRouter>
                <StylesProvider injectFirst={true}>
                    <App />
                </StylesProvider>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
function displayUnhandledErrorMessage(event) {
    console.error(
        `UNHANDLED PROMISE REJECTION\nType: ${event.type}\nMessage: ${event.reason.message}\nStack: ${event.reason.stack}`
    );

    if (
        easyFitStore.getState().notification.maintenanceMessage &&
        easyFitStore.getState().notification.maintenanceMessage.isDisplayed
    ) {
        console.warn(
            `Reason: ${event.reason.statusText}\nStatus: ${event.reason.status}`
        );
    } else {
        // Prevent the default handling (such as outputting the
        // error to the console)
        const previousUserSelectionAction =
            easyFitStore.getState().notification.alertMessage.userSelections[0]
                .action;
        const isPreviousActionInit =
            previousUserSelectionAction === NotificationCloseHandlerType.Init;
        // if no previous alert messages were triggered, then dispatch the generic alert below
        if (isPreviousActionInit) {
            easyFitStore.dispatch(
                updateNotification({
                    ...easyFitStore.getState().notification,
                    alertMessage: {
                        title: t(
                            'common:Common_Feature:ErrorHandling:TitleText2'
                        ),
                        message: [
                            t('common:Common_Feature:ErrorHandling:Text2'),
                        ],
                        detailMessage: event.reason.message,
                        userSelections: [
                            {
                                content: t('common:Common_Feature:ok'),
                                action: NotificationCloseHandlerType.Reload,
                            },
                        ],
                        isDisplayed: true,
                    },
                })
            );
        }
    }
}

function displayNetworkErrorMessage() {
    let workflow = window.location.pathname.substring(1);
    const workflowTypes = Object.values(MainViewWorkflow);
    if (!workflowTypes.includes(workflow)) {
        LoggingService.error({
            componentName: 'NetworkLossErrorDialog',
            args: ['Invalid workflow type'],
        });
        workflow = MainViewWorkflow.AppFitting; //default workflow type so that user can still exit during network loss
    }
    easyFitStore.dispatch(
        updateNotification({
            ...easyFitStore.getState().notification,
            alertMessage: {
                title: t(
                    'common:Common_Feature:ErrorHandling:NetworkConnectionLoss:Title'
                ),
                message: [
                    t(
                        'common:Common_Feature:ErrorHandling:NetworkConnectionLoss:Text1'
                    ),
                    t(
                        'common:Common_Feature:ErrorHandling:NetworkConnectionLoss:Text2'
                    ),
                ],
                detailMessage: t(
                    'common:Common_Feature:ErrorHandling:NetworkConnectionLoss:Text3'
                ),
                userSelections: [
                    {
                        content: t('common:Common_Feature:ok'),
                        action: NotificationCloseHandlerType.NetworkLossExit,
                    },
                ],
                workflow: workflow,
                isDisplayed: true,
            },
        })
    );
}
