import { ThemeProvider } from '@material-ui/core';
import axios, { AxiosError } from 'axios';
import axiosRetry from 'axios-retry';
import 'prevent-pull-refresh';
import React, {
    useContext,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import './App.css';
import ThemeContext from './CommonAssets/Styles/ThemeContext';
import DeviceInfoPage from './Pages/MainComponents/DeviceInfo/DeviceInfoPage';
import useAppFineTuningTransitionHandler from './Pages/MainComponents/MainView/AppFineTuningTransitionHandlerHook';
import useAppFittingTransitionHandler from './Pages/MainComponents/MainView/AppFittingTransitionHandlerHook';
import MainView from './Pages/MainComponents/MainView/MainView';
import { MainViewWorkflow } from './Pages/MainComponents/MainView/MainViewModel';
import AuthService from './Services/Authentication/AuthService';
import LoggingService from './Services/LoggingService';
import MaintenanceService from './Services/Maintenance/MaintenanceService';
import TelemetryProvider from './Services/Monitoring/TelemetryProvider';
import AlertMessagePopUp from './SharedComponents/Dialog/AlertMessage/AlertMessageDialog';
import MaintenanceDialog from './SharedComponents/Dialog/MainatenanceDialog/MaintenanceDialog';
import { IMaintenanceDialogResource } from './SharedComponents/Dialog/MainatenanceDialog/MaintenanceDialogResourceHook';
import PageNotFoundDialog from './SharedComponents/Dialog/PageNotFoundDialog/PageNotFoundDialog';
import Polling from './Utils/Polling';
import { updateNotification } from './Redux/Reducers/NotificationSlice';
import easyFitStore from './Redux/Store/EasyFitStore';
import { preloadImages, imageFolderEnum } from './Utils/PreloadImageUtils';

axiosRetry(axios, {
    retries: Number(process.env.REACT_APP_MaxRetryCount) + 1,
    shouldResetTimeout: true,
    retryCondition: (error: AxiosError) => {
        return (
            axiosRetry.isNetworkOrIdempotentRequestError(error) ||
            error.code === 'ECONNABORTED' ||
            error.code === 'ENOTFOUND'
        );
    },
    retryDelay: (retryCount: number, error) => {
        LoggingService.warn({
            componentName: axiosRetry.name,
            args: [`Retry attempt: ${retryCount}\nerror: ${error}`],
        });
        return retryCount * 500;
    },
});

const App = (): JSX.Element => {
    const appInsightsKey = process.env.REACT_APP_APP_INSIGHT_KEY
        ? process.env.REACT_APP_APP_INSIGHT_KEY
        : '';

    const dispatch = useDispatch();

    const maintenanceDialogDisplay = useRef(false);

    const [maintenanceDisplay, setMaintenanceDisplay] =
        useState<IMaintenanceDialogResource>({
            maintenancePeriod: 0,
        });

    const axiosMaintenanceResponseInterceptor = useCallback(() => {
        axios.interceptors.response.use(
            async (response) => {
                if (
                    response.status > 300 &&
                    !maintenanceDialogDisplay.current
                ) {
                    LoggingService.warn({
                        componentName: App.name,
                        args: [
                            `API returned status code ${response.status} with text ${response.statusText}`,
                        ],
                    });
                    maintenanceDialogDisplay.current = true;

                    const resp =
                        await MaintenanceService.GetMaintenancePageResponse();

                    if (
                        resp.IsAppInMaintenanceMode &&
                        resp.MaintenancePeriodEndTime > Date.now() / 1000
                    ) {
                        setMaintenanceDisplay({
                            ...maintenanceDisplay,
                            maintenancePeriod: resp.MaintenancePeriodEndTime,
                        });
                        dispatch(
                            updateNotification({
                                ...easyFitStore.getState().notification,
                                maintenanceMessage: {
                                    isDisplayed: resp.IsAppInMaintenanceMode,
                                    maintenancePeriod:
                                        resp.MaintenancePeriodEndTime,
                                },
                            })
                        );
                    }
                }
                return Promise.resolve(response);
            },
            (error) => {
                // in case of 429 error due to too many requests, retry
                if (error.response.status == 429) {
                    return axios.request(error.response.config);
                }
                LoggingService.error({
                    componentName: App.name,
                    args: [`${error}`],
                });
                return Promise.reject(error);
            }
        );
    }, [dispatch, maintenanceDisplay]);

    const maintenanceHandler = {
        maintenancePeriod: maintenanceDisplay?.maintenancePeriod,
        display: maintenanceDisplay?.maintenancePeriod ? true : false,
        onClose: () => {
            maintenanceDialogDisplay.current = false;
            setMaintenanceDisplay({
                ...maintenanceDisplay,
                maintenancePeriod: 0,
            });
        },
    };

    const renderMaintenanceDialog = () => {
        const { display, onClose, maintenancePeriod } = maintenanceHandler;
        return (
            <MaintenanceDialog
                displayed={display}
                onClose={onClose}
                maintenancePeriod={maintenancePeriod}
            />
        );
    };

    const { theme } = useContext(ThemeContext);

    Polling(async () => {
        if (await AuthService.adB2cInstance.doesAccessTokenExist())
            AuthService.adB2cInstance.acquireAccessToken();
    }, 60000 * 1);

    useEffect(() => {
        preloadImages(imageFolderEnum.sharedAcrossWorkflows);
        axiosMaintenanceResponseInterceptor();
    }, [axiosMaintenanceResponseInterceptor]);

    return (
        <ThemeProvider theme={theme}>
            <div className="App-body">
                <AlertMessagePopUp />
                {renderMaintenanceDialog()}
                <TelemetryProvider instrumentationKey={appInsightsKey}>
                    <Switch>
                        <Route exact path="/app/fitting">
                            <MainView
                                workflowType={MainViewWorkflow.AppFitting}
                                useTransitionHandler={
                                    useAppFittingTransitionHandler
                                }
                            />
                        </Route>
                        <Route exact path="/app/finetuning">
                            <MainView
                                workflowType={MainViewWorkflow.AppFineTuning}
                                useTransitionHandler={
                                    useAppFineTuningTransitionHandler
                                }
                            />
                        </Route>
                        <Route exact path="/deviceinfo">
                            <DeviceInfoPage />
                        </Route>
                        <Route path="/">
                            <PageNotFoundDialog />
                        </Route>
                    </Switch>
                </TelemetryProvider>
            </div>
        </ThemeProvider>
    );
};

export default App;
