import { useContext } from 'react';
import LocalStorageService from '../../../Services/LocalStorageService';
import PatientManagementService from '../../../Services/PatientManagementService';
import AudioService from '../../../Services/AudioService';
import ArcToneService from '../../../Services/ArcToneService';
import { CustomerServiceContactService } from '../../../Services/Configuration/CustomerServiceContactService';
import { VoidReturn, linkCallBacksResource } from '../../../Utils/PageUtils';
import { HearingAssessmentState, HLAAContext } from '../HLAAContext';
import { MainViewContext } from '../../MainComponents/MainView/MainViewContext';
import { TransitionAction } from '../../MainComponents/MainView/MainViewModel';
import { goToUrl } from '../../../Utils/NavigationUtils';

export type HearingAssessmentInfoPageHandler = {
    constant0ToneHeardReach: () => VoidReturn;
    linkCallBacks: linkCallBacksResource;
    primaryAction: () => VoidReturn;
    secondaryAction: () => VoidReturn;
    error: string;
};

export default function useHearingAssessmentInfoPageHandler(): HearingAssessmentInfoPageHandler {
    const error = '';
    const viewContext = useContext(MainViewContext);
    const {
        info,
        hearingAssessmentState,
        setHearingAssessmentState,
        exitHearingAssessment,
    } = useContext(HLAAContext);

    const reinstruct = () => {
        info.current.startKeepAlive = true;
        setHearingAssessmentState(HearingAssessmentState.AssessmentOneSide);
    };

    const constant0ToneHeardReach = () => {
        LocalStorageService.serviceInstance.setPairingAddress('left', -1);
        LocalStorageService.serviceInstance.setPairingAddress('right', -1);
        exitHearingAssessment();
    };

    const openHyperLink = () => {
        goToUrl(
            CustomerServiceContactService.getCountrySpecificOrionCustomerServiceContact(
                false
            ),
            true
        );
    };

    const redoHLAA = async () => {
        await PatientManagementService.redoHLAA();
        LocalStorageService.serviceInstance.clear(false);
        await PatientManagementService.getPatient();
    };

    const linkCallBacks = {
        customerServiceLink: openHyperLink,
    };

    async function primaryAction() {
        if (hearingAssessmentState === HearingAssessmentState.Reinstruct) {
            reinstruct();
        } else if (
            hearingAssessmentState ===
            HearingAssessmentState.AbortMaxPresentedToneReach
        ) {
            await AudioService.serviceInstance.initAudioContext();
            for await (const side of ['left', 'right']) {
                const brandId =
                    LocalStorageService.serviceInstance.getDeviceBrandId(side);
                const pairingAddress =
                    LocalStorageService.serviceInstance.getPairingAddress(side);
                await AudioService.serviceInstance.prepAudio(
                    ArcToneService.getExitAssessment(brandId, pairingAddress),
                    `${side} exit hlaa`
                );
                await AudioService.serviceInstance.prepAudio(
                    ArcToneService.unmuteDevice(brandId, pairingAddress),
                    `${side} unmute`
                );
            }
            await redoHLAA();
            viewContext.doTransition(TransitionAction.RedoHLAAViaJourney);
        }
    }

    async function secondaryAction() {
        if (hearingAssessmentState === HearingAssessmentState.Reinstruct) {
            viewContext.doTransition(TransitionAction.AssessmentReinstructExit);
        } else if (
            hearingAssessmentState ===
            HearingAssessmentState.AbortMaxPresentedToneReach
        ) {
            viewContext.doTransition(
                TransitionAction.AssessmentMaxToneReachedExit
            );
        }
    }

    return {
        constant0ToneHeardReach,
        linkCallBacks,
        primaryAction,
        secondaryAction,
        error,
    };
}
