import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { AxiosResponse } from 'axios';
import { Device } from '../../../Models/Patient/Device';
import { Patient } from '../../../Models/Patient/Patient';
import { getCurrentLocale, t } from '../../../Services/LocalizationService';
import LocalStorageService from '../../../Services/LocalStorageService';
import LoggingService from '../../../Services/LoggingService';
import PatientManagementService from '../../../Services/PatientManagementService';
import {
    isBluetoothDevice,
    mapEncodedModelIdToDefault,
} from '../../../Utils/BluetoothDeviceUtils';
import CompletedWorkflowService, {
    Workflow,
} from '../../../Utils/CompletedWorkflow';
import { getCouplingOrDefault } from '../../../Utils/CouplingUtils';
import { isValidDevice } from '../../../Utils/ValidDeviceUtils';
import {
    DialogState,
    MainViewWorkflow,
    PageState,
    TransitionAction,
    ViewState,
} from './MainViewModel';
import * as deviceInfo from 'react-device-detect';
import Telemetry from '../../../Services/Monitoring/Telemetry';
import { NotificationCloseHandlerType } from '../../../Redux/Models/Notification/NotificationCloseHandlerType';
import { updateNotification } from '../../../Redux/Reducers/NotificationSlice';
import easyFitStore from '../../../Redux/Store/EasyFitStore';
import { DeviceInfo } from '../../../Models/DeviceInfo';
import { ExitStatus } from '../../../Services/ExitRouteService';
import {
    getDeviceFolderNameFromModelId,
    preloadImages,
} from '../../../Utils/PreloadImageUtils';
import { getDeviceIdFromParams } from '../../../Services/ResourceService';

type DeviceInfoType = {
    leftModel: string;
    rightModel: string;
    leftArc: number;
    rightArc: number;
};

export const getBackButtonViewState = (viewState: ViewState): ViewState => {
    let nextPageState: PageState;
    switch (viewState.page) {
        case PageState.Welcome:
            nextPageState = PageState.SplashScreen;
            break;
        case PageState.HiAssemble:
            nextPageState = PageState.Welcome;
            break;
        default:
            nextPageState = viewState.page;
    }
    return {
        ...viewState,
        page: nextPageState,
    };
};

export const getArcPairingAddress = (
    patient: Patient | undefined,
    side: string
): number => {
    if (!patient) return -1;

    if (isDeviceSwitched()) return -1;

    const devices = patient.devices;

    const deviceIndex = devices.findIndex(
        (device: Device) => device.side.toString().toLowerCase() === side
    );

    return devices[deviceIndex].pairingAddress;
};

export const getDeviceInfoFromURLParams = (
    patient: Patient | undefined
): DeviceInfoType => {
    // Sanitization of URL params
    const params = new URLSearchParams(location.search);
    const platformType = params.get('platformId');
    const leftModel = mapEncodedModelIdToDefault(
        params.get('leftModel') as string,
        platformType as string
    );
    const rightModel = mapEncodedModelIdToDefault(
        params.get('rightModel') as string,
        platformType as string
    );
    const leftArc = params.get('leftArc')
        ? Number(params.get('leftArc'))
        : !isBluetoothDevice(leftModel)
        ? getArcPairingAddress(patient, 'left')
        : NaN;
    const rightArc = params.get('rightArc')
        ? Number(params.get('rightArc'))
        : !isBluetoothDevice(rightModel)
        ? getArcPairingAddress(patient, 'right')
        : NaN;
    if (!patient) {
        // set devices called in consent analytic workflow
        LocalStorageService.serviceInstance.setQueryParams(params.toString());
    }
    return { leftModel, rightModel, leftArc, rightArc };
};

export const setDeviceListFromParams = (): DeviceInfo[] => {
    const deviceList = [];
    const paramsString = LocalStorageService.serviceInstance.getQueryParams();
    const params = new URLSearchParams(paramsString);
    const platformType = params.get('platformId');
    const leftModelId = mapEncodedModelIdToDefault(
        params.get('leftModel') as string,
        platformType as string
    );
    const rightModelId = mapEncodedModelIdToDefault(
        params.get('rightModel') as string,
        platformType as string
    );
    const leftPairingAddress =
        params.get('leftArc') == null ? -1 : Number(params.get('leftArc'));
    const rightPairingAddress =
        params.get('rightArc') == null ? -1 : Number(params.get('rightArc'));

    if (leftModelId) {
        deviceList.push({
            side: 'Left',
            brandId: 15,
            modelId: leftModelId,
            pairingAddress: leftPairingAddress,
            couplingId: getCouplingOrDefault('left', leftModelId),
        });
    }
    if (rightModelId) {
        deviceList.push({
            side: 'Right',
            brandId: 15,
            modelId: rightModelId,
            pairingAddress: rightPairingAddress,
            couplingId: getCouplingOrDefault('right', rightModelId),
        });
    }
    return deviceList;
};

export function validatePreviousState(
    currentState: PageState,
    action: TransitionAction,
    ...expectedStates: PageState[]
): boolean {
    let isValidState = false;
    for (let i = 0; i < expectedStates.length; i++) {
        if (currentState === expectedStates[i]) {
            isValidState = true;
        }
    }
    if (!isValidState) {
        LoggingService.error({
            componentName: 'TransitionHandlerUtils.validatePreviousState',
            args: [
                `Unexpected transition from ${PageState[currentState]} with action ${TransitionAction[action]}`,
            ],
        });
    }

    return isValidState;
}

export function handleActionError(
    currentState: PageState,
    action: TransitionAction
): PageState {
    LoggingService.error({
        componentName: 'TransitionHandlerUtils.handleActionError',
        args: [
            `Transition ${TransitionAction[action]} not implemented (called on page: ${PageState[currentState]})`,
        ],
    });

    return PageState.None;
}

export function getDebugRoute(): PageState {
    // do fake routing from url params
    const params = new URLSearchParams(location.search);
    const route = params.get('route');
    return PageState[route as keyof typeof PageState];
}

export function getPageStateParam(): PageState {
    const params = new URLSearchParams(location.search);
    const route = params.get('pageState');
    return PageState[route as keyof typeof PageState];
}

export async function updateDeviceWithURLParams(
    patient: Patient | undefined
): Promise<void> {
    if (patient) {
        const urlParamsFromPatient = getDeviceInfoFromURLParams(patient);
        const devices = [
            {
                side: 'Left',
                brandId: 15,
                modelId: urlParamsFromPatient.leftModel as string,
                pairingAddress: urlParamsFromPatient.leftArc,
                couplingId: getCouplingOrDefault(
                    'left',
                    urlParamsFromPatient.leftModel as string
                ),
            },
            {
                side: 'Right',
                brandId: 15,
                modelId: urlParamsFromPatient.rightModel as string,
                pairingAddress: urlParamsFromPatient.rightArc,
                couplingId: getCouplingOrDefault(
                    'right',
                    urlParamsFromPatient.rightModel as string
                ),
            },
        ];
        await PatientManagementService.SetDevices(devices);
    }
}

export async function isURLParamsValid(
    patient: Patient | undefined
): Promise<boolean> {
    const urlParamsFromPatient = getDeviceInfoFromURLParams(patient);
    const leftModel = urlParamsFromPatient.leftModel;
    const rightModel = urlParamsFromPatient.rightModel;
    const leftArc = urlParamsFromPatient.leftArc;
    const rightArc = urlParamsFromPatient.rightArc;

    if (leftModel !== rightModel) {
        LoggingService.error({
            componentName: 'URLParamsValidation',
            args: [`Different model ids, ${location.search}`],
        });
        return false;
    }

    if (!isValidDevice(leftModel)) {
        LoggingService.error({
            componentName: 'URLParamsValidation',
            args: [`Not a valid device, ${location.search}`],
        });
        return false;
    }

    if (
        isBluetoothDevice(leftModel) &&
        (isNaN(leftArc) ||
            isNaN(rightArc) ||
            leftArc < 0 ||
            leftArc > 15 ||
            rightArc < 0 ||
            rightArc > 15)
    ) {
        LoggingService.error({
            componentName: 'URLParamsValidation',
            args: [`Invalid pairing addresses, ${location.search}`],
        });
        return false;
    }

    return true;
}

export function checkAndGetNotCompletedWorkflowNextPage(
    completedWorkflows: Workflow,
    defaultPageState: PageState,
    pageToShowIfNotComplete?: PageState
): PageState {
    let nextPage = defaultPageState;
    if (
        !CompletedWorkflowService.isCompletedMandatoryWorkflow(
            completedWorkflows
        )
    ) {
        nextPage = CompletedWorkflowService.getNextMandatoryWorkflow(
            completedWorkflows,
            true
        );

        if (pageToShowIfNotComplete) {
            nextPage = pageToShowIfNotComplete;
        }
    }

    return nextPage;
}

export const supportedPageStateQueryParamsAppFitting: PageState[] = [
    PageState.RedoHLAA,
];

export const supportedPageStateQueryParamsFineTuning: PageState[] = [
    PageState.RedoLoudness,
];

export function handleInvalidToken(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    response: AxiosResponse<any>,
    workflowType: MainViewWorkflow
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
    if (response.status === 401 || response.status === 403) {
        LoggingService.warn({
            componentName: handleInvalidToken.name,
            args: [
                `API returned status code ${response.status} with text ${response.statusText} for invalid token response`,
            ],
        });
        easyFitStore.dispatch(
            updateNotification({
                ...easyFitStore.getState().notification,
                alertMessage: {
                    title: t('common:Common_Feature:ErrorHandling:TitleText2'),
                    message: [
                        t('common:Common_Feature:ErrorHandling:ExitText2'),
                    ],
                    detailMessage: '',
                    userSelections: [
                        {
                            content: t('common:Common_Feature:ok'),
                            action: NotificationCloseHandlerType.UnauthenticatedExit,
                        },
                    ],
                    isDisplayed: true,
                    workflow: workflowType.toString(),
                },
            })
        );
    }
    return Promise.resolve(response);
}

const isValidArcPairingAddresses = (patient: Patient | undefined): boolean => {
    const leftPairingAddress = getArcPairingAddress(patient, 'left');
    const rightPairingAddress = getArcPairingAddress(patient, 'right');

    const bothSidesUnpaired =
        leftPairingAddress === -1 && rightPairingAddress === -1;

    const bothSidesPaired =
        leftPairingAddress >= 0 &&
        leftPairingAddress <= 15 &&
        rightPairingAddress >= 0 &&
        rightPairingAddress <= 15;

    return bothSidesUnpaired || bothSidesPaired;
};

export async function determineInitialState(
    patient: Patient | undefined
): Promise<ViewState> {
    const supportedHis = await PatientManagementService.getSupporedHis();
    LocalStorageService.serviceInstance.setSupportedHis(supportedHis);
    Telemetry.getInstance().trackTrace(
        `Patient ID: ${LocalStorageService.serviceInstance.getPatientId()}, Device Details: ${JSON.stringify(
            deviceInfo.deviceDetect()
        )}, Screen Resolution: ${
            window.screen.width * window.devicePixelRatio
        } x ${window.screen.height * window.devicePixelRatio}`,
        SeverityLevel.Information
    );
    const userSelectedLocale = getCurrentLocale();

    if (patient && patient.preferredLanguage !== userSelectedLocale) {
        await PatientManagementService.updateLanguage(userSelectedLocale);
    }
    let nextView = {
        dialog: DialogState.None,
        page: PageState.None,
    };

    const isRedoFit =
        LocalStorageService.serviceInstance.getMandatoryWorkflowCompletedOnce();
    LocalStorageService.serviceInstance.setIsRedoFitState(isRedoFit);

    const isValid = await isURLParamsValid(patient);
    const modelId = getDeviceInfoFromURLParams(patient).leftModel;

    if (isValid) {
        preloadImages(getDeviceFolderNameFromModelId(modelId));
        if (!isBluetoothDevice(modelId)) {
            const validArcDevicePairingAddresses =
                isValidArcPairingAddresses(patient);
            if (!validArcDevicePairingAddresses) {
                nextView = {
                    dialog: DialogState.None,
                    page: PageState.Journey,
                };
                return nextView;
            }
        }
        await updateDeviceWithURLParams(patient);
        if (isDeviceSwitched())
            LocalStorageService.serviceInstance.setSwitchDevice(true);
    } else {
        nextView = {
            dialog: DialogState.None,
            page: PageState.DeviceIncompatibleError,
        };
    }

    return nextView;
}

export const isDeviceSwitched = (): boolean => {
    const currentModelId =
        LocalStorageService.serviceInstance.getDeviceModelId('left');
    const paramModelId = getDeviceIdFromParams();
    return (
        currentModelId != -1 &&
        paramModelId != -1 &&
        currentModelId != paramModelId
    );
};

export const getExitUrlFitStatus = (): ExitStatus => {
    return LocalStorageService.serviceInstance.getIsRedoFitState()
        ? ExitStatus.RedoFitSuccess
        : ExitStatus.FirstFitSuccess;
};

export const getPairingAddressParam = (): string => {
    return `?leftArc=${LocalStorageService.serviceInstance.getPairingAddress(
        'left'
    )}&rightArc=${LocalStorageService.serviceInstance.getPairingAddress(
        'right'
    )}`;
};
