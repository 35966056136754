import { useContext } from 'react';
import { MainViewContext } from '../../MainComponents/MainView/MainViewContext';
import { TransitionAction } from '../../MainComponents/MainView/MainViewModel';

export type CompleteRecommendationHandler = {
    handleCompleteRecommendationContinue: () => void;
};

function useCompleteRecommendationHandler(): CompleteRecommendationHandler {
    const { doTransition } = useContext(MainViewContext);

    const handleCompleteRecommendationContinue = () => {
        doTransition(TransitionAction.CompleteRecommendationContinue);
    };
    return {
        handleCompleteRecommendationContinue,
    };
}

export default useCompleteRecommendationHandler;
