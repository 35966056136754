import { useContext } from 'react';
import { VoidReturn } from '../../../../Utils/PageUtils';
import { MobileDeviceUnmutedCheckContext } from '../MobileDeviceUnmutedCheckContext';
import { MobileDeviceUnmutedCheckState } from '../MobileDeviceUnmutedCheckModel';
import AudioService from '../../../../Services/AudioService';

export type MobileDeviceUnmutedCheckStartPageHandler = {
    playMedia: () => VoidReturn;
};

export default function useMobileDeviceUnmutedCheckStartPageHandler(): MobileDeviceUnmutedCheckStartPageHandler {
    const { setCurrentMobileDeviceUnmutedCheckState } = useContext(
        MobileDeviceUnmutedCheckContext
    );

    const playMedia = async () => {
        setCurrentMobileDeviceUnmutedCheckState(
            MobileDeviceUnmutedCheckState.Play
        );
        await AudioService.serviceInstance.initAudioContext();
    };

    return {
        playMedia,
    };
}
