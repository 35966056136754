import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Side } from '../../../Models/Side';
import { CarouselFooterContent } from '../../../SharedComponents/Carousel/CarouselFooter';
import { HeaderActionType } from '../../../SharedComponents/Header/HeaderActionType';
import { PairingCarouselResource } from '../PairingCarouselPage';
import { PairingContext } from '../PairingContext';
import { replaceSideWithText } from '../PairingUtils';
import config from './InsertInstructionConfig.json';
import insertionInstructionResource from './InsertionInstructionResource.json';
import { deviceModel } from '../../../Models/SupportedDeviceModel';
import { getUserDeviceModel } from '../../../Services/ResourceService';
import { switchBasedOnDevice } from '../../../Utils/PageUtils';

function useInsertInstructionResource(
    currentStep: number
): PairingCarouselResource {
    const {
        state: { context },
    } = useContext(PairingContext);
    const { t } = useTranslation();

    const generalKey = switchBasedOnDevice(getUserDeviceModel(), {
        OrionGo: 'orionGo:OrionGo_Device:InsertInstructions_Feature',
        OrionComplete:
            'orionComplete:OrionComplete_Device:InsertInstructions_Feature',
    }) as string;

    const header = {
        id: insertionInstructionResource.header.id,
        text: t(`${generalKey}${insertionInstructionResource.header.text}`),
        type: HeaderActionType.Menu,
    };

    const [imgSrcList, instructionList] = [[], []] as [
        string[],
        CarouselFooterContent[]
    ];
    //this will need to pass down using constant, context from the route for monaural, binaural
    const sideToInstruct = ['left' as Side, 'right' as Side];
    const userDeviceModel = getUserDeviceModel();
    const stepPerSide =
        userDeviceModel === deviceModel.OrionGo
            ? config.OrionGo.stepPerSide
            : config.OrionComplete.stepPerSide;

    const totalStep = sideToInstruct.length * stepPerSide;
    sideToInstruct.forEach((side: Side) => {
        for (let index = 1; index <= stepPerSide; index++) {
            imgSrcList.push(
                `/resources/OrionGo/insertion-${side}-${index}.svg`
            );
            instructionList.push({
                instructionContent: t(
                    `${generalKey}${insertionInstructionResource.instructions.text}${index}`,
                    replaceSideWithText(t, context.activeSide)
                ),
            });
        }
    });
    if (sideToInstruct.length > 1) {
        imgSrcList.push(`/resources/OrionGo/insertion-final.svg`);
        instructionList.push({
            instructionContent: t(
                `${generalKey}${insertionInstructionResource.instructions.textFinal}`
            ),
        });
    }

    const continueButton = {
        id: insertionInstructionResource.continueButton.id,
        label: t(insertionInstructionResource.continueButton.text),
    };

    const title = {
        id: insertionInstructionResource.title.id,
        text:
            currentStep < totalStep
                ? t(
                      `${insertionInstructionResource.title.text}${
                          context.activeSide === 'left' ? 'Left' : 'Right'
                      }${insertionInstructionResource.title.title}`
                  )
                : String.fromCharCode(0x200b), //zero width space to prevent empty string element collapse and shift the layout
    };

    return {
        header,
        imgSrcList,
        instructionList,
        button: continueButton,
        title,
    };
}

export default useInsertInstructionResource;
